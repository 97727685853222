// eslint-disable-next-line import/prefer-default-export
export const util = {
  methods: {
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${c[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
  },
}
